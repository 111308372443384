import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/office-developer.json';
import emailjs from 'emailjs-com';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ContactForm = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("sendEmail...");

        emailjs.sendForm('service_c7uj7en', 'template_w0bdzg6', e.target, 'user_Io6hQDXLobAbA5jUwZICt')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" />
                        Nous contacter
                    </span>
                    <h2>Quel est votre projet?</h2>
                    <p>Vos informations ne seront pas partagées.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        {/* <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div> */}
                        <Lottie
                            options={defaultOptions}
                            height="90%"
                            width="90%"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={(e) => {
                                sendEmail(e);
                                alert("Merci de nous avoir contacté! \nNous vous répondrons dans les plus brefs délais.");
                            }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Votre nom" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Votre adresse email" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone" className="form-control" required placeholder="Votre numéro de téléphone" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Écrivez votre message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i>
                                            Envoyez maintenant <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    

                </div>
            </div>
        </section>
    )
}

export default ContactForm